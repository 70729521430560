<template>
  <div>
    <loading-spinner v-if="caseData === null"></loading-spinner>
    <b-card
          v-if="caseData !== null"
          bg-variant="light"
          header-text-variant="white"
          header-bg-variant="secondary"
          class="mb-4">
        <h5 slot="header" class="mb-0">{{ "case" | terminology({isTitle: true}) }} Detail</h5>

        <case-detail-stats :case-data="caseData" :should-show-enrollments-link="false" :userPermissions="userPermissions"></case-detail-stats>

          <div slot="footer" class="row justify-content-end">
            <b-button variant="outline-secondary" @click="handleClose">
              <font-awesome-icon icon="times"></font-awesome-icon> Close
            </b-button>
          </div>

      </b-card>
  </div>
</template>

<script>
import {toRef, ref, watch} from "vue";
import Api from "../api"
import CaseDetailStats from "./case-detail-stats.vue";

export default {
  name: "case-detail-stats-wrap",
  components: {CaseDetailStats},
  props: {
    caseId: {type: Number},
    userPermissions: {type: Object, required: true},
  },
  setup(props, {emit}) {
    const caseId = toRef(props, 'caseId');
    const caseData = ref(null);

    async function loadCaseData() {
      caseData.value = null;
      caseData.value = await Api.getCaseDetails(caseId.value);
    }
    watch(caseId, loadCaseData);
    loadCaseData();

    function handleClose() {
      emit('close')
    }

    return {
      caseData,
      handleClose,
      userPermissions: props.userPermissions
    }
  }
}
</script>

<style scoped>

</style>
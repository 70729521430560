<template>
  <div>
    <b-row>
      <b-col>
            <h4>{{caseData.name}}
        <b-button v-if="shouldShowEnrollmentsLink" class="ml-2" size="sm" variant="outline-primary" :to="{name: 'enrollments', params: {case_id: caseData.case_id}}">View Apps</b-button>
    </h4>

      </b-col>
      <b-col>
        <div class="text-muted text-right"><small>Note: Stat updates may be delayed up to 1 hour</small></div>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col v-if="userPermissions.canChangeCase()" md="6" class="mb-3">
        <b-table-simple hover responsive>
          <b-tbody>
            <b-tr v-if="siteConfig.enableCaseSetupEnrollmentPeriods">
              <b-th>Enrollment Period:</b-th>
              <b-td>{{ formattedEnrollmentPeriod }}</b-td>
            </b-tr>
            <b-tr v-if="siteConfig.enableCaseSetupEnrollmentModes">
              <b-th>Modes:</b-th>
              <b-td>{{ formattedModes }}</b-td>
            </b-tr>
            <b-tr>
              <b-th> Products:</b-th>
              <b-td>{{ caseData.product_names }}</b-td>
            </b-tr>
<!--            <b-tr>-->
<!--              Not yet - EF 2022-12-23 -->
<!--              <b-th>Offered States:</b-th>-->
<!--              <b-td>{{ caseOfferedStates }}</b-td>-->
<!--            </b-tr>-->
            <b-tr>
              <b-th>Delivery</b-th>
              <b-td>
                  <b-form-radio-group
                    buttons
                    :button-variant="(isDelivery) ? 'outline-success': 'outline-warning'"
                    name="delivery"
                    v-model="isDelivery"
                    :options="[{'text': 'Transmit', 'value': true}, {'text': 'Paused', 'value': false}]"
                    @change="handleDeliveryChanged"
                  >
                  </b-form-radio-group>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th>Status</b-th>
              <b-td>
                  <b-form-radio-group
                    buttons
                    :button-variant="(isActive) ? 'outline-success': 'outline-danger'"
                    name="isActive"
                    v-model="isActive"
                    :options="[{'text': 'Active', 'value': true}, {'text': 'Inactive', 'value': false}]"
                    @change="handleStatusChanged"
                  >
                  </b-form-radio-group>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <b-modal static v-model="showConfirmDeliveryModal"
                 @cancel="handleDeliveryCancel"
                 @ok="handleDeliveryConfirm"
                 title="Confirm">
          <div v-if="isDelivery">Are you sure you want to turn on delivery transmit?</div>
          <div v-else>Are you sure you want to pause delivery transmit?</div>
        </b-modal>

        <b-modal v-model="isSaving">
          <loading-spinner></loading-spinner>
        </b-modal>


        <b-modal static v-model="showConfirmStatusModal"
                 @cancel="handleStatusChangedCancel"
                 @ok="handleStatusChangedConfirm"
                 title="Confirm"
        >
          <div v-if="isActive">Are you sure you want to reactivate this {{ "case" | terminology({}) }}?</div>
          <div v-else>Are you sure you want to deactivate this {{ "case" | terminology({}) }}?</div>
        </b-modal>

      </b-col>
      <b-col md="6" class="mb-3">
        <h6>Application Status</h6>
        <div class="ml-4">
          <table class="table numbers">
            <tr>
              <td class="pr-2 text-right border-0">{{ caseData.num_pending_employee_sig }}</td>
              <td class="border-0">Pending Applicant Signature</td>
            </tr>
            <tr>
              <td class="pr-2 text-right">{{ caseData.num_pending_agent_sig }}</td>
              <td>Pending Agent Signature</td>
            </tr>
            <tr>
              <td class="pr-2 text-right">{{ caseData.num_paused }}</td>
              <td>Paused {{ "enrollment" | terminology({isTitle: true, isPlural: true}) }}</td>
            </tr>
            <tr>
              <td class="pr-2 text-right">{{ formattedNumQueued }}</td>
              <td>Completed, but queued for delivery</td>
            </tr>
            <tr>
              <td class="pr-2 text-right">{{ formattedNumDelivered }}</td>
              <td>Delivered to home office</td>
            </tr>
          </table>
        </div>
      </b-col>
      <b-col md="6" class="mb-3">
        <h6>Participation</h6>
        <div class="ml-4">
          {{ censusStats.totalCensus }} total census persons
          <br>
          {{ censusStats.numCensusCompleted }} ({{ censusStats.percentCensusCompleted }}) Census completed,
          {{ censusStats.numCensusRemain }} ({{ censusStats.percentCensusRemain }}) remain
          <br>
          <div class="ml-4">
            {{ censusStats.numCensusEnrolled }} ({{ censusStats.percentCensusEnrolled }}) applied for at least one
            product
            <br>
            {{ censusStats.numCensusWaived }} ({{ censusStats.percentCensusWaived }}) waived all products
          </div>
        </div>
      </b-col>
      <b-col md="6" class="mb-3">
        <h6>Product Mix</h6>
        <b-row v-if="hasAnyEnrollments()">
          <b-col cols="6">

            <case-product-count-chart
              :product-stats="all_product_stats"
            ></case-product-count-chart>
          </b-col>
          <b-col cols="6">
            <case-product-premium-chart
              :product-stats="all_product_stats"
            ></case-product-premium-chart>
          </b-col>
        </b-row>
        <br>
        <table class="table hover small striped responsive">
          <thead>
          <tr>
            <th>Product</th>
            <th>Count</th>
            <th>Annual Premium</th>
          </tr>
          </thead>
          <tr v-for="product_stats in all_product_stats.products">
            <td>{{ product_stats.product }}</td>
            <td>{{ product_stats.count }} ({{ product_stats.count_percentage }})</td>
            <td>{{ product_stats.formattedPremium }} ({{ product_stats.premium_percentage }})</td>
          </tr>
          <tr>
            <th>Totals:</th>
            <th>{{ all_product_stats.totals.count }}</th>
            <th>{{ all_product_stats.totals.formattedPremium }}</th>
          </tr>

        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {toRefs, computed, ref, watch} from "vue"
import CaseProductCountChart from "./case-product-count-chart.vue";
import CaseProductPremiumChart from "./case-product-premium-chart.vue";
import formats from "../models/formats";
import API from "../api"
import {siteConfig} from "../app"
import states from "../models/states";

export default {
  name: "case-detail-stats",
  components: {CaseProductCountChart, CaseProductPremiumChart},
  props: {
    // Detailed case stats (aggregated) data required.
    userPermissions: {type: Object, required: true},
    caseData: {type: Object, required: true},
    shouldShowEnrollmentsLink: {type: Boolean, default: true},
  },
  setup(props, {attrs}) {
    let {caseData} = toRefs(props);
    const formattedEnrollmentPeriod = computed(() => {
      if (caseData.value.period_start === 'open') {
        return 'Open';
      } else {
        return `${caseData.value.period_start} to ${caseData.value.period_end}`
      }
    })
    const formattedModes = computed(() => {
      return caseData.value.enrollment_mode;
    });
    const formattedNumQueued = computed(() => {
      return Math.max(0, caseData.value.num_queued);
    });
    const formattedNumDelivered = computed(() => {
      if (caseData.value.num_enrolled === null) {
        return 0;
      }
      const numDelivered = caseData.value.num_enrolled - formattedNumQueued.value;
      if (numDelivered < 0) {
        return 0;
      }
      return numDelivered;
    })

    const all_product_stats = computed(() => {
      let stats = [];
      let totals = {
        premium: 0.0,
        formattedPremium: "--",
        count: 0,
      }
      for (let i = 1; i <= 5; i++) {
        const productName = caseData.value['product_'+i+'_name'];
        if (productName !== "") {
          const premium = parseFloat(caseData.value['product_'+i+'_premium']);
          let p_stats = {
            product: productName,
            count: caseData.value['product_'+i+'_num_enrolled'],
            premium: premium,
            formattedPremium: formats.premium(premium),
          }
          totals.count += caseData.value['product_'+i+'_num_enrolled'];
          totals.premium += premium
          totals.formattedPremium = formats.premium(totals.premium)
          stats.push(p_stats);
        }
      }

      // Product "other" is all other product data, if any.
      if (caseData.value['product_other_num_enrolled'] > 0) {
        const premium = parseFloat(caseData.value['product_other_premium']);
          let p_stats = {
            product: 'Other Products',
            count: caseData.value['product_other_num_enrolled'],
            premium: premium,
            formattedPremium: formats.premium(premium),
          }
          totals.count += caseData.value['product_other_num_enrolled'];
          totals.premium += premium
          totals.formattedPremium = formats.premium(totals.premium)
          stats.push(p_stats);
      }

      // Compute percentages of totals for each product.
      for (let i = 0; i < stats.length; i++) {
        const productStats = stats[i];
        if (totals.count === 0) {
          productStats.count_percentage = '--%';
        } else {
          productStats.count_percentage = `${formats.percent(productStats.count / totals.count)}`;
        }
        if (totals.premium < 0.01) {
          productStats.premium_percentage = '--%';
        } else {
          productStats.premium_percentage = `${formats.percent(productStats.premium / totals.premium)}`;
        }
      }

      return {products: stats, totals: totals};
    });

    function hasAnyEnrollments() {
      const sumEnrollCounts = (accumulator, el) => accumulator + el.count;

      return all_product_stats.value.products.reduce(sumEnrollCounts, 0) > 0;
    }

    // Toggles
    const isSaving = ref(false);
    const isDelivery = ref(caseData.value.delivery === 'transmit');
    const showConfirmDeliveryModal = ref(false);
    function handleDeliveryChanged() {
      showConfirmDeliveryModal.value = true;
    }
    async function handleDeliveryConfirm() {
      isSaving.value = true;
      // save case
      const caseObj = await API.getCase(caseData.value.case_id);
      caseObj.delivery_paused = (isDelivery.value === false);
      await API.updateCase(caseObj);

      showConfirmDeliveryModal.value = false;
      isSaving.value = false;

      // reload page
      window.location.reload();
    }
    function handleDeliveryCancel() {
      isDelivery.value = !isDelivery.value;
      showConfirmDeliveryModal.value = false;
    }


    const isActive = ref(caseData.value.status === 'Active');
    const showConfirmStatusModal = ref(false);
    function handleStatusChanged() {
      showConfirmStatusModal.value = true;
    }
    async function handleStatusChangedConfirm() {
      isSaving.value = true;

      if (isActive.value) {
        // reactivate case
        await API.reactivateCase(caseData.value.case_id);
      } else {
        // deactivate (delete) case
        await API.deleteCase(caseData.value.case_id);
      }

      showConfirmStatusModal.value = false;
      isSaving.value = false;

      // reload page
      window.location.reload();
    }
    function handleStatusChangedCancel() {
      isActive.value = !isActive.value;
      showConfirmStatusModal.value = false;
    }

    // census stats
    const censusStats = computed(function() {
      const totalCensus = caseData.value.num_census;
      const numCensusEnrolled = caseData.value.num_census_completed;
      const numCensusWaived = caseData.value.num_census_waived;

      const censusWaivedFraction = (totalCensus > 0) ? numCensusWaived / totalCensus : 0;
      const percentCensusWaived = formats.percent(censusWaivedFraction);
      const censusEnrolledFraction = (totalCensus > 0) ? numCensusEnrolled / totalCensus : 0;
      const percentCensusEnrolled = formats.percent(censusEnrolledFraction);

      const numCensusCompleted = numCensusEnrolled + numCensusWaived;

      const percentCompletedFraction = (totalCensus > 0) ? numCensusCompleted / totalCensus : 0;
      const percentCensusCompleted = formats.percent(percentCompletedFraction)
      const numCensusRemain = totalCensus - numCensusCompleted;
      const censusRemainFraction = (totalCensus > 0) ? numCensusRemain / totalCensus : 0;
      const percentCensusRemain = formats.percent(censusRemainFraction);


      return {
        totalCensus,
        numCensusCompleted,
        percentCensusCompleted,
        numCensusRemain,
        percentCensusRemain,
        numCensusEnrolled,
        percentCensusEnrolled,
        numCensusWaived,
        percentCensusWaived,
      }
    })



    return {
      siteConfig,

      isSaving,

      isDelivery,
      handleDeliveryChanged,
      showConfirmDeliveryModal,
      handleDeliveryConfirm,
      handleDeliveryCancel,

      isActive,
      showConfirmStatusModal,
      handleStatusChanged,
      handleStatusChangedConfirm,
      handleStatusChangedCancel,

      formattedEnrollmentPeriod,
      formattedModes,
      formattedNumQueued,
      formattedNumDelivered,
      hasAnyEnrollments,
      all_product_stats,
      userPermissions: props.userPermissions,
      censusStats,
    }
  },
  computed: {
    caseOfferedStates() {
      if (!this.caseData || !this.caseData.offered_states) {
        return states.map(s => s.value).join(', ');
      }
      // start with all the states and filter down to the ones offered
      return states.filter(stateOption => this.caseData.offered_states.includes(stateOption.value)).map(stateOption => stateOption.value).join(', ');;
    }
  }
}
</script>

<style scoped>
  small {
    font-size: .9rem;
    margin-left: 1.5rem;
  }
  h6 {
    font-size: 1.25rem;
    font-weight: bold;
  }
  table.numbers td {
    border: 0;
    padding: 0;
  }
</style>

import { render, staticRenderFns } from "./product_options_case_settings.vue?vue&type=template&id=2a78197d&scoped=true"
import script from "./product_options_case_settings.vue?vue&type=script&lang=js"
export * from "./product_options_case_settings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a78197d",
  null
  
)

export default component.exports
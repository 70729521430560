// Support for older browsers in production
//import "core-js/stable";
//import "regenerator-runtime/runtime";

// Support for datepicker in browsers lacking datepicker on date inputs.
import 'date-input-polyfill';

// Admin section CSS
import "./enrollments.css";

import Vue from 'vue';

import "./icons";


import BootstrapVue from 'bootstrap-vue'
import loginForm from './login-form.js'
import template from './app.html'
import censusDetails from './census/census-details'

// Bootstrap and Bootstrap-Vue CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Multiselect and Multiselect CSS
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
// Use the standard vue-router for navigation.
import VueRouter from 'vue-router'
import forgotPassword from "./forgot-password";
import registerAgent from "./register-agent";
import cases from "./cases/cases.vue"
import caseDetails from "./cases/case-details.vue";
import enrollments from "./Enrollments.vue";
import enrollmentsDetails from "./EnrollmentDetails";
import manageUsers from "./manage-users";
import Reports from "./reports/Reports.vue";
import userDetails from "./user-details";
import profile from "./profile";
import resetPassword from "./reset-password";
import notAuthorized from "./403";
import Api from "./api";
Vue.use(VueRouter);

  // Error logging
// import * as Sentry from '@sentry/browser';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// Currency input
import VueCurrencyInput from 'vue-currency-input';
Vue.use(VueCurrencyInput);


// Use di-vue-mask plugin adds a "v-mask" directive to allow simple UI text box masking, like phone numbers and SSNs.
import VueMask from 'di-vue-mask'
Vue.use(VueMask);

import UserPermissions from "./models/user_permissions";


// Enable Bootstrap-Vue plugin.
Vue.use(BootstrapVue);
Vue.component('multiselect', Multiselect);

import {getTerminology, getTerminologyVueFilter} from './terminology'
import StaticPage from "./StaticPage";
import Wizard from './enrollment/WizardComponent'
import bus from "./bus";
import deliveries from "./deliveries/deliveries";
import RemoteSign from "./enrollment/RemoteSign";
import SelfEnroll from "./enrollment/SelfEnroll";
import AgentEnrollRemoteSig from "./enrollment/AgentEnrollRemoteSig.vue";
import CallCenterReview from "./enrollment/CallCenterReview";

export let router = {};
export let siteConfig = null;

/**
 *
 * @type {?TerminologyInterface}
 */
export let terminology = null;


// Wrap the app startup in raven context for better error tracking
function initApp(siteConfig, terminology) {

  let loginDefaultPath;
  let enrollmentPaths = {};

  loginDefaultPath = '/'+terminology.getEnrollmentsPathName();

  // Terminology filter
  Vue.filter('terminology', getTerminologyVueFilter(terminology));

  // Define some routes
  router = new VueRouter({
    mode: 'history',
    routes: [
      {
        path: '/',
        name: 'loginForm',
        component: loginForm,
        anyoneCanAccess: true,
        props: {defaultPath: loginDefaultPath}
      },
      {
        path: `/${terminology.getWizardPathName()}`,
        name: 'EnrollmentWizard',
        component: Wizard,
      },
      {
        path: `/${terminology.getCasesPathName()}`,
        name: 'cases',
        component: cases
      },
      {
        path: `/${terminology.getCasesPathName()}/:id`,
        name: 'case',
        component: caseDetails,
      },
      {
        path: '/confirmation/:token',
        name: 'emailConfirmation',
        component: loginForm,
        anyoneCanAccess: true
      },
      {
        path: '/reset-password/:token',
        name: 'resetPassword',
        component: resetPassword,
        anyoneCanAccess: true
      },
      {
        path: '/invitation/:token',
        name: 'resetPasswordInvitation',
        // TODO double check that this is supposed to be resetPassword
        component: resetPassword,
        anyoneCanAccess: true
      },
      {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: forgotPassword,
        anyoneCanAccess: true
      },
      {
        path: '/register-user',
        name: 'registerAgent',
        component: registerAgent,
        anyoneCanAccess: true
      },
      {
        path: `/${terminology.getEnrollmentsPathName()}`,
        name: 'enrollments',
        component: enrollments,
      },
      {
        path: `/${terminology.getEnrollmentsPathName()}/:case_id`,
        name: 'enrollmentsCase',
        component: enrollments,
      },
      {
        path: `/${terminology.getEnrollmentDetailsPathName()}/:id`,
        name: 'enrollmentDetails',
        component: enrollmentsDetails,
      },
      {
        path: '/census-details/:id',
        name: 'censusDetails',
        component: censusDetails
      },
      {
        path: '/manage-users',
        name: 'manageUsers',
        component: manageUsers,
        props: true
      },
      {
        path: '/reports',
        name: 'reports',
        component: Reports,
        props: true
      },
      {
        path: '/deliveries',
        name: 'deliveries',
        component: deliveries,
        props: true
      },
      {
        path: '/user-details/:id',
        name: 'userDetails',
        component: userDetails
      },
      {
        path: '/profile',
        name: 'profile',
        component: profile
      },
      {
        path: `/${terminology.getWizardPathName()}/finished`,
        name: 'WizardComplete',
        component: StaticPage,
        props: {endpoint: '/pages/enrollment-complete-redirect'},
      },
      {
        path: `/${terminology.getWizardPathName()}/auto-paused`,
        name: 'WizardConfFailedAutoPaused',
        component: StaticPage,
        props: {endpoint: '/pages/enrollment-conference-session-failed-auto-pause-redirect'}
      },
      {
        path: `/sign/:caseSlug/:tokenUUID`,
        name: 'RemoteSign',
        component: RemoteSign,
        props: true,
        anyoneCanAccess: true
      },

      // alias to catch places we may have not redirected correctly - EF 2022-09-13
      {
        path: `/pages/complete`,
        name: 'RemoteWizardCompleteRedirect',
        redirect: {name: 'RemoteWizardComplete'},
        anyoneCanAccess: true
      },
      {
        path: `/complete`,
        name: 'RemoteWizardComplete',
        component: StaticPage,
        props: {endpoint: '/pages/complete'},
        anyoneCanAccess: true
      },
      {
        path: `/enroll/:caseSlug/:tokenUUID`,
        name: 'SelfEnroll',
        component: SelfEnroll,
        props: true,
        anyoneCanAccess: true
      },
      {
        path: `/agent-enroll-remote-sig/:caseSlug/:tokenUUID`,
        name: 'AgentEnrollRemoteSig',
        component: AgentEnrollRemoteSig,
        props: true,
        anyoneCanAccess: true
      },
      {
        path: `/review/:caseSlug/:tokenUUID`,
        name: 'CallCenterReview',
        component: CallCenterReview,
        props: true,
        anyoneCanAccess: true
      },
      {
        path: '/403',
        name: 'notAuthorized',
        component: notAuthorized,
        anyoneCanAccess: true
      },
    ],
    scrollBehavior (to, from, savedPosition) {
      // Go to top of page when changing route, unless saved position.
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    }
  });



  let app = new Vue({
    el: '#app',
    template: template,
    data: function() {
      return {
        isLoading: true,
        user: {},
        userPermissions: new UserPermissions(),
        siteConfig: siteConfig,
        terminology: terminology,
        changingPages: false,
        shouldShowNavigation: true,
      };
    },
    components: {},
    methods: {
      doLogout() {
        Api.logout()
          .then(() => {
            this.user = {};
            this.userPermissions.clearUser();

            router.push('/', () => {
              delete localStorage.savedTabs;
            });
          });
      },
      goToReports() {
        router.push('/reports');
      },
      goToManageUsers() {
        router.push('/manage-users');
      },
      goToHome() {
        router.push(loginDefaultPath);
      },
      goToProfile() {
        router.push('/profile');
      },
      retrieveUser(to) {
        this.changingPages = true;
        return Api.me()
          .then(response => {
            if (!response.errors) {
              this.user = response;
              this.userPermissions.setUser(this.user);

              // Set error tracking user if present
              if (window.SENTRY_DSN) {
                Sentry.setUser({
                  email: response.email,
                  first_name: response.first_name,
                  last_name: response.last_name,
                  id: response.id
                });
              }

              this.checkRoutePermission(to);
            } else {
              // if retrieval of user into fails, push to login and pass the path that was requested
              this.$router.push({ name: 'loginForm', query: { redirectTo: to.path } });
            }
            this.changingPages = false;
          });
      },
      checkRoutePermission(to) {
        if ((to.path === '/manage-users' && !this.userCanAccessManageUsers()) ||
          (to.name === 'enrollments' && !this.userCanAccessEnrollments()) ||
          (to.path === '/cases' && !this.userCanAccessCases()) ||
          (to.path === '/reports' && !this.userCanAccessReports())) {
          this.$router.push('/403');
        }
      },
      userCanAccessManageUsers() {
        return this.userPermissions.canAccessManageUsers();
      },
      userCanAccessEnrollments() {
        return this.userPermissions.canAccessEnrollments();
      },
      userCanAccessCases() {
        return this.userPermissions.canAccessCases();
      },
      userCanAccessProfile() {
        return this.userPermissions.canAccessProfile();
      },
      userCanAccessReports() {
        return this.userPermissions.canAccessReports();
      },
      userCanAccessDeliveries() {
        return this.userPermissions.canAccessDeliveries();
      },

      getRouteObj(toPath) {
        let route = null;
        router.options.routes.forEach(r => {
          let path = r.path;
          if (path !== '/') {
            if (path.includes(':')) { // if path allows a parameter, remove it from the string
              path = path.substring(0, path.indexOf(':'));
            }

            if (toPath.includes(path)) {
              route = r;
            }
          }
        });
        return route;
      }
    },
    router: router,
    computed: {
      showManageUsersMenuOption() {
        return this.userCanAccessManageUsers();
      },
      showEnrollmentsMenuOption() {
        return this.userCanAccessEnrollments();
      },
      showCasesMenuOption() {
        return this.userCanAccessCases();
      },
      showReportsMenuOption() {
        return this.userCanAccessReports();
      },
      showDeliveriesMenuOption() {
        return this.userCanAccessDeliveries();
      },
      logoURL() {
        if (this.siteConfig) {
          return this.siteConfig.logoURL;
        } else {
          return "";
        }
      }
    },
    created() {
      console.log(`Created app EnrollmentEngine build ${process.env.VUE_APP_EE_VERSION}`);
      let route = this.getRouteObj(this.$route.path);
      if (route && !route.anyoneCanAccess) {
        this.retrieveUser({path: this.$route.path}
        // Not using .finally() here to simplify support for IE11 and Edge, which are hard to polyfill fetch return promises.
        ).then(() => {
          this.isLoading = false;
        }).catch(() => {
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }

      // Listen for layout changes
      bus.$on("hide-navigation", () => {
        this.shouldShowNavigation = false;
      });
      bus.$on("show-navigation", () => {
        this.shouldShowNavigation = true;
      });
    },
    mounted() {

    },
    watch: {
      $route(to, from) {
        let route = this.getRouteObj(to.path);

        if (route && !route.anyoneCanAccess) {
          this.retrieveUser(to);
        }
      }
    }
  });


  // try {
  //   if (window.SENTRY_DSN && window.SENTRY_DSN !== 'None') {
  //     Sentry.init({
  //       Vue,
  //       dsn: window.SENTRY_DSN,
  //       integrations: [
  //         new BrowserTracing({
  //           routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //           tracingOrigins: ["localhost", process.env.VUE_APP_API_BASE_PATH, window.location, /^\//],
  //         }),
  //       ],
  //       // Set tracesSampleRate to 1.0 to capture 100%
  //       // of transactions for performance monitoring.
  //       // We recommend adjusting this value in production
  //       tracesSampleRate: 0.25,
  //     });
  //   }
  // } catch (e) {
  //   console.error('Error initializing Sentry', e);
  // }

}

Api.getSiteConfig().then((config) => {
  // Set global exports
  siteConfig = config;
  terminology = getTerminology(siteConfig);

  // Init App
  initApp(config, terminology);
}).catch((e) => {
  alert("There was a problem loading the application")
})


import { render, staticRenderFns } from "./cases-assign-tags.vue?vue&type=template&id=7cc72fd0&scoped=true"
import script from "./cases-assign-tags.vue?vue&type=script&lang=js"
export * from "./cases-assign-tags.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cc72fd0",
  null
  
)

export default component.exports
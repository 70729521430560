<template>
<b-form @submit.prevent="handleFiltersChanged">
  <b-form-row>
    <b-col class="col-0" cols="12" md="3">
      <b-form-select v-model="caseStatusFilter">
        <option value="all">All {{$root.terminology.formatCase({isPlural: true, isTitle: true})}}</option>
        <option value="active">Active {{$root.terminology.formatCase({isPlural: true, isTitle: true})}}</option>
        <option value="inactive">Inactive {{$root.terminology.formatCase({isPlural: true, isTitle: true})}}</option>
      </b-form-select>
    </b-col>
    <b-col class="col-0" cols="12" md="3">
      <b-form-select v-model="caseSearchProperty" :options="propertyOptions"></b-form-select>
    </b-col>
    <b-col cols="12" md="6">
      <b-input-group v-if="shouldShowTextSearch">
        <b-form-input v-model="caseSearchText" placeholder="Search"></b-form-input>
        <b-input-group-append>
          <b-btn variant="primary" @click="handleFiltersChanged"><i class="fa fa-search"></i></b-btn>
        </b-input-group-append>
      </b-input-group>
      <b-form-select v-model="deliveryFilter" v-if="caseSearchProperty === 'delivery'">
        <option value="all">All</option>
        <option value="paused">Paused Only</option>
        <option value="transmit">Transmit Only</option>
      </b-form-select>

    </b-col>
  </b-form-row>
  <b-form-row class="mt-3">
  </b-form-row>
</b-form>
</template>

<script>
import {ref, watch, computed} from "vue";
import {terminology} from "../app";

export default {
  name: "CaseSearchFilters",
  props: {
    searchText: {type: String, required: true},
    searchProperty: {type: String, required: true},
    statusFilter: {type: String, required: true},
  },
  emits: ['update:searchText', 'update:searchProperty'],
  setup(props, {emit}) {
    // Initialize form with current value of props
    const caseSearchText = ref(props.searchText);
    const caseSearchProperty = ref(props.searchProperty);
    const deliveryFilter = ref('all');
    const caseStatusFilter = ref(props.statusFilter);

    function handleFiltersChanged() {
      let searchVal;
      if (caseSearchProperty.value === 'delivery') {
        searchVal = deliveryFilter.value;
      } else {
        searchVal = caseSearchText.value;
      }
      emit("update:searchText", searchVal);
      emit("update:searchProperty", caseSearchProperty.value);
      emit("update:statusFilter", caseStatusFilter.value);
    }

    watch([caseSearchText, caseSearchProperty, deliveryFilter, caseStatusFilter],
        handleFiltersChanged)

    const shouldShowTextSearch = computed(() => {
      return (
          caseSearchProperty.value === 'all' ||
          caseSearchProperty.value === 'name' ||
          caseSearchProperty.value === 'agents' ||
          caseSearchProperty.value === 'tags' ||
          caseSearchProperty.value === 'products'
      )
    })

    return {
      caseSearchText,
      caseSearchProperty,
      caseStatusFilter,
      deliveryFilter,
      handleFiltersChanged,
      shouldShowTextSearch,
      propertyOptions: [
        {value: "all", text: "All"},
        {value: "name", text: `${terminology.formatCase({isTitle: true})} Name`},
        {value: "agents", text: "Agent"},
        {value: "tags", text: "Tags"},
        {value: "products", text: "Products"},
        {value: "delivery", text: "Delivery"},
      ]
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <loading-spinner v-show="isLoading" useWholePage></loading-spinner>
    <div v-if="!isLoading && caseData.case_id === undefined">
      <b-alert variant="danger" :show="true">{{ "case" | terminology({isTitle: true}) }} not found</b-alert>
    </div>
    <div v-if="!isLoading && caseData.case_id !== undefined">
      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <b-link
              @click="$router.push({name: 'cases'})">
              {{ "case" | terminology({isTitle: true, isPlural: true}) }}
            </b-link>
          </li>
          <li class="breadcrumb-item active">
              {{ "case" | terminology({isTitle: true}) }} Detail
          </li>
        </ol>
      </nav>

      <b-card
          bg-variant="light"
          header-text-variant="white"
          header-bg-variant="secondary"
          class="mb-4">
        <h5 slot="header" class="mb-0">{{ "case" | terminology({isTitle: true}) }} Detail</h5>

        <case-detail-stats :case-data="caseData" :userPermissions="userPermissions"></case-detail-stats>

      </b-card>


      <setupcase
          v-if="userPermissions.isHomeOfficeAdmin()"
          :Case="caseObj"
          :products="productOptions"
          :tags="tagOptions"
          :users="userOptions"
          :userPermissions="userPermissions"
          :shouldShowCloseBtn="false"
          @delete-case-listener="handleCaseDeleted"
      >
      </setupcase>

    </div>


    <b-modal
      title="Saving"
      v-model="showSavingModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true">

      <b-container>
        <div>
          <loading-spinner size="large"></loading-spinner>
        </div>
      </b-container>

      <div slot="modal-footer" class="row align-self-end"></div>
    </b-modal>

  </div>
</template>

<script>
import bus from "../bus"
import {router} from '../app'
import {onMounted, ref, watch, computed} from 'vue'
import API from '../api'
import setupcase from "../case_setup/setup-case"
import caseDetailStats from "./case-detail-stats.vue"
import {getProductOptions, getTagOptions, getUserOptions} from "../case_setup/fetch-setup-options";

export default {
  name: "CaseDetails",
  components: {
    setupcase,
    caseDetailStats,
  },
  setup(props, {attrs}) {
    const isCaseDataLoading = ref(true);
    const isCaseSetupDataLoading = ref(false);
    const isLoading = computed(() => {
      return isCaseDataLoading.value || isCaseSetupDataLoading.value;
    });

    // Data for case statistics and other details
    const caseData = ref(null);

    // Data for case setup tab
    const caseObj = ref(null);

    async function refreshCaseData(caseId) {
      isCaseDataLoading.value = true;
      [caseData.value, caseObj.value] = await Promise.all([
          API.getCaseDetails(caseId), API.getCase(caseId)
      ])
      isCaseDataLoading.value = false;
    }

    watch(
      () => router.currentRoute.params.id,
      async newId => {
        await refreshCaseData(newId);
      }
    )

    // Initialize case setup dependencies
    const productOptions = ref();
    const tagOptions = ref();
    const userOptions = ref();
    const userPermissions = ref(attrs.userPermissions);
    const showSavingModal = ref(false);

    // this.userPermissions.canManageUsersAndProducts()
    onMounted(async () => {
      isCaseSetupDataLoading.value = true;
      let loadingAsyncCalls = [
          refreshCaseData(router.currentRoute.params.id),
      ];
      if (attrs.userPermissions.canChangeCase()) {
        loadingAsyncCalls.push(
            getProductOptions(),
            getTagOptions(),
            getUserOptions(),
        );
      }
      const results = await Promise.all(loadingAsyncCalls);

      if (attrs.userPermissions.canChangeCase()) {
        [ _,
          productOptions.value,
          tagOptions.value,
          userOptions.value,
        ] = results;
      }

      bus.$on('case-saving-started', () => {
        showSavingModal.value = true;
      });

      bus.$on('case-saving-ended', async () => {
        await refreshCaseData(router.currentRoute.params.id);
        showSavingModal.value = false;
      });

      isCaseSetupDataLoading.value = false;
    });

    function handleCaseDeleted() {
      // Navigate back to cases
      router.push({name: 'cases'})
    }


    return {
      isLoading,
      caseData,
      caseObj,
      tagOptions,
      userOptions,
      productOptions,
      userPermissions,
      showSavingModal,
      handleCaseDeleted,
    }
  },
}
</script>

<style scoped>

</style>